import loadable from '@loadable/component';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useQuery, gql } from '@apollo/client';

import MainLayout from './pages/layouts/MainLayout';
import { Loader } from './components/Loader';
import { config } from './i18n';
import Giveaway from './pages/views/Giveaway';

interface PageProps {
  path: string;
}

const Page = loadable<PageProps>(
  async (props) => import(`./pages/${props.path}`),
  {
    cacheKey: (props) => props.path,
    fallback: <Loader />,
  },
) as any;

export const routers = [
  { path: '', element: 'Home' },
  { path: 'faq', element: 'views/Faq' },
  { path: 'blog', element: 'views/Blog' },
  { path: 'reviews/vk', element: 'views/Reviews' },
  { path: 'reviews/trustpilot', element: 'views/Reviews' },
  { path: 'reviews/video', element: 'views/Reviews' },
  { path: 'blog/post/:id', element: 'views/Post' },
  { path: 'blog/tag/:tag', element: 'views/Blog' },
  { path: 'blog/search/:search', element: 'views/Blog' },
  { path: 'agreement', element: 'views/Agreement' },
  { path: 'user/:id', element: 'views/User' },
  { path: 'search/:id', element: 'views/Search' },
  { path: 'case/:url', element: 'views/Play' },
  { path: 'case/:url/odds', element: 'views/Odds' },
  { path: 'event/:url', element: 'views/Event' },
  { path: 'battle_pass/:url', element: 'views/BattlePass' },
  { path: 'profile', element: 'views/Profile' },
  { path: 'settings', element: 'views/Settings' },
  { path: 'contract', element: 'views/Contract' },
  { path: 'upgrade', element: 'views/Upgrade' },
  { path: 'wheel', element: 'views/Wheel' },
  { path: 'refunds', element: 'views/Refunds' },
  { path: 'contacts', element: 'views/Contacts' },
  { path: 'privacy_policy', element: 'views/PrivacyPolicy' },
  { path: 'pay', element: 'views/Pay' },
];

const GET_PROFILE_QUERY = gql`
  query getProfile {
    getProfile {
      id
      group
    }
  }
`;

const RenderRoutes = () => {
  const { loading, data, error } = useQuery(GET_PROFILE_QUERY);

  if (loading) return <Spin />;
  if (error) return <>{error.message}</>;

  const { getProfile } = data;

  const routesConfig = [
    ...routers,
    getProfile && getProfile.group !== 0
      ? { path: 'giveaway', element: <Giveaway /> }
      : { path: 'giveaway', element: <Navigate replace to="/" /> },
  ];

  return (
    <Routes>
      <Route element={<MainLayout />}>
        {['', ...config.fallbackLng].map((lang) =>
          routesConfig.map((route) => (
            <Route
              key={`/${lang}${route.path}`}
              path={`/${lang}${route.path}`}
              element={
                typeof route.element === 'string' ? (
                  <Page path={route.element} />
                ) : (
                  route.element
                )
              }
            />
          )),
        )}
        <Route path="*" element={<Page path="error/NotFound" />} />
      </Route>
    </Routes>
  );
};

export default RenderRoutes;
